<template>
  <div class="interaction">
      <div class="apparel">
        <div class="center" >
          <div class="exchange">
            <h2>智能穿戴</h2>
            <h2>健康管理</h2>
            <p>穿戴设备与AI算法协同下的实时长周期健康管理</p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "interaction.vue",

}
</script>

<style scoped>

.interaction {
}

.apparel {
  margin: 0 auto;
  background-image:url("../../../assets/health-img.png");
  background-repeat:no-repeat;
  background-position: right;
  height:900px;
}

.apparel .center {
  width: 1200px;
  margin: 0 auto;

}

.apparel .exchange {
  /*width:1200px;*/
  padding-top:360px
}

 .apparel .exchange h2{
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 90px;
}

 .apparel .exchange p{
  font-family: 'PingFang SC';
  font-weight: 400;
  font-size: 24px;
  line-height: 60px;
  color: #999999;
  margin-top:50px
}

</style>
