<template>
  <div class="company">

    <div class="centre" >
      <div class="imgText">
          <div>
            <img src="@/assets/Maschine Robot 3.png">
          </div>
          <img class="picture" src="@/assets/mechanical.png">
          <div class="typeface">
            <p>
              Maschine Robot(姬械机科技)是一家基于穿戴传感器和智能交互通信与计算技术的穿戴机器人Wearable Robotics科技公司，研发中心分别设置于美国波士顿和中国北京。其核心研发团队成员主要来自世界一流高校与相关科技产业，主要服务领域为健康管理与养老保健。
            </p>
            <br />
            <p>
              重点产品体系主要为智能穿戴设备和交互控制系统，旨在通过穿戴与人工智能计算技术，设计研发生产智能穿戴机器人产品，解决人口老龄化带给人们日益增长的健康难题，打造未来时代的健康与交互新模式。
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "company.vue",


  // data() {
  //   return {
  //     positionY1: 900,
  //     Y1: 0,
  //     ratio: 0.1,
  //   }
  // },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll)
  //   let bgTest = document.querySelector('.centre')
  //   this.Y1 = bgTest.offsetTop * this.ratio
  // },
  // methods: {
  //   handleScroll() {
  //     let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  //     this.positionY1 = this.Y1 - scrollTop * this.ratio
  //   }
  // }
}
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
}

.company .centre {
  margin: 0 auto;
  background-image:url("../../../assets/science-img.png");
  background-repeat:no-repeat;
  background-size:cover;
  height:900px;
  /*background-attachment: fixed;*/
}

.centre .imgText{
  margin: auto;
  width: 635px;
  padding: 174px 0 0 200px;

}


.centre .imgText .picture {
  height:24px;
  margin-top:50px;
  margin-bottom:66px
}

.centre .imgText .diamond {
  position: absolute;
  bottom: -172px;
  right: -269px;
}

.centre .imgText .typeface {
  width: 612px;
  height: 230px;
}

.centre .imgText .typeface p {
  letter-spacing: 1px;
  width: 650px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
}

</style>
