<template>
  <div class="page-wrap" v-if="viewport">
    <banner :nav-index="0">
      <template slot="banner">
        <div class="banner">
          <div class="centre" :style="{'will-change': 'transform',transform: `translate3d(0,${transLateY}px,0)`}">
            <div class="letter">
              <h1>脑机交互与俱身智能</h1>
              <div class="line"></div>

              <p class="top" >致力于人机交互创新技术研发，扩展人类第六感。通过智能穿戴机器人技术，让人类实现数字与机器永生。
              </p>
              <p>通过智能穿戴机器人产品提升生活幸福感，让人们享受高品质的个性化娱乐与健康交互服务。</p>

              <div class="frame" >
                Reshape The Future.
              </div>
            </div>
            <div class="untitled">
              <img class="cerebrum" src="@/assets/web2.0animation.gif">
              <img class="round" src="@/assets/Ellipse 3.png">
              <img class="slash" src="@/assets/Ellipse 4.png">
            </div>

          </div>
        </div>
      </template>
    </banner>
    <div style="position: relative;z-index: 2;background-color: #fff">
      <company></company>
      <interaction></interaction>
      <machine></machine>
      <provide></provide>
      <cooperation></cooperation>
      <div class="footer-box">
        <relation :isHome="true"></relation>
      </div>
    </div>
  </div>
  <div v-else class="phone-wrap">
    <div class="background">
      <div class="nav">
        <div class="logo">
          <img src="@/assets/phone/phone-logo.png">
        </div>
        <div @click="onList" class="fork">
          <span class="iconfont icon-liebiaoshitucaidan"></span>
        </div>
      </div>
      <div class="alternately">
        <h1>脑机交互与俱身智能</h1>
        <div class="line"></div>
        <div class="brain">
          <img class="pic" src="@/assets/web2.0animation.gif">
          <img class="across" src="@/assets/phone/Ellipse3.png">
          <img class="vertical" src="@/assets/phone/Ellipse4.png">
        </div>
        <h2>致力于人机交互创新技术研发，扩展人类第六感。通过智能穿戴机器人技术，让人类实现数字与机器永生。</h2>
        <h3>通过智能穿戴机器人产品提升生活幸福感，让人们享受高品质的个性化娱乐与健康交互服务。</h3>
      </div>

      <div v-show="list" class="list">
        <phoneNav @onHide="onHide"></phoneNav>
      </div>
    </div>
    <div class="technology">
      <div class="mro">
        <img src="@/assets/phone/MaschineRobot5.png">
      </div>
      <div class="pic">
        <img src="@/assets/phone/Technology.png">
      </div>
      <div class="line"></div>
      <div class="letter">
        <img src="@/assets/phone/ReshapetheFuture.png">
      </div>
      <div class="text">
        <p>
          Maschine Robot(姬械机科技)是一家基于穿戴传感器和智能交互通信与计算技术的穿戴机器人Wearable Robotics
          科技公司，研发中心分别设置于美国波士顿和中国北京。其核心研发团队成员主要来自世界一流高校与相关科技产业，主要服务领域为健康管理与养老保健。
        </p>

        <p class="top">
          重点产品体系主要为智能穿戴设备和交互控制系统，旨在通过穿戴与人工智能计算技术，设计研发生产智能穿戴机器人产品，解决人口老龄化带给人们日益增长的健康难题，打造未来时代的健康与交互新模式。
        </p>
      </div>
    </div>

    <div class="img-text" :style="{backgroundImage: `url(${banner1})`}">
      <div class="text">
        <h1>智能穿戴</h1>
        <h1>健康管理</h1>
        <p class="top">穿戴设备与AI算法</p>
        <p>协同下的实时长周期健康管理</p>
      </div>
    </div>
    <div class="img-text end" :style="{backgroundImage: `url(${banner2})`}">
      <div class="text">
        <h1>智能穿戴</h1>
        <h1>人机交互</h1>
        <p class="top">脑机接口与AI算法</p>
        <p>结合的意念万物互联</p>
      </div>
    </div>
    <div class="img-text" :style="{backgroundImage: `url(${banner3})`}">
      <div class="text">
        <h1>人机交互</h1>
        <h1>俱身智能</h1>
        <p class="top">人形机器人与智能穿戴</p>
        <p>结合的感知交互应用</p>
      </div>
    </div>
    <div class="cooperation">
      <h1>合作单位</h1>
      <div class="unit">
        <div class="icon">
            <img src="@/assets/phone/logo1.png">
            <p>MIT</p>
        </div>
        <div class="icon">
            <img src="@/assets/phone/logo2.png">
            <p>北京协和医院</p>
        </div>
        <div class="icon">
            <img src="@/assets/phone/anzhen.png">
            <p>安贞医院</p>
        </div>
        <div class="icon">
            <img src="@/assets/phone/fuwai.png">
            <p>阜外医院</p>
        </div>
        <div class="icon">
            <img src="@/assets/phone/logo3.png">
            <p>斯坦福大学</p>
        </div>
        <div class="icon">
            <img src="@/assets/phone/logo4.png">
            <p>上海交大</p>
        </div>
      </div>
    </div>
    <div>
      <phoneFooter></phoneFooter>
    </div>
  </div>
</template>

<script>


import banner from '@/components/banner'
import relation from '@/components/footer'
import company from './components/company'
import interaction from './components/interaction'
import machine from './components/machine'
import cooperation from './components/cooperation'
import provide from './components/provide'
import phoneNav from '@/components/phoneNav/index'
import phoneFooter from "@/components/phoneFooter";

export default {
  components: {
    phoneFooter,
    phoneNav,
    banner,
    company,
    interaction,
    cooperation,
    machine,
    relation,
    provide,
  },
  data() {
    return {
      toTop: 0,
      transLateY: 0,
      clientWidth: 0,
      screenWidth:null,
      viewport: true,
      list: false,
      banner1: require('../../assets/phone/banner1.png'),
      banner2: require('../../assets/phone/banner2.jpg'),
      banner3: require('../../assets/phone/banner3.png'),
    }

  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  watch: {
    screenWidth: function (n, o) {
      if (n <= 1024) {
        this.viewport=false
      } else {
        this.viewport=true
      }
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    if (document.documentElement.clientWidth < 1280) {
      this.viewport = false
    }
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth

      })()
    }
  },

  methods: {

    onList() {
      setTimeout(() => {
        this.list = true
      }, 200)

    },

    onHide() {
      setTimeout(() => {
        this.list = false
      }, 200)

    },

    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop < 900) {
        if (this.toTop <= scrollTop) {
          this.transLateY = scrollTop / 10
        } else {
          this.transLateY = -scrollTop / 10
        }
      }
      this.toTop = scrollTop
    }
  },

}
</script>

<style scoped>


.banner {
  height: 900px;
}

.banner .centre {
  display: flex;
  margin: 0 auto;
  height: 944px;
  width: 1200px;
}

.banner .centre .letter {
  padding-top: 100px;
}

.banner .centre .letter h1 {
  color: #FFFFFF;
  font-size: 68px;
  font-weight: 600;
  font-family: 'PingFang SC';
  font-style: normal;
  letter-spacing: 2px;
}

.banner .centre .letter .line {
  margin-top: 45px;
  width: 60px;
  height: 6px;
  background-color: #FFFFFF;
}


.banner .centre .letter p {
  width: 700px;
  font-size: 24px;
  line-height: 40px;
  color: #FFFFFF;
  font-family: 'PingFang SC';
  letter-spacing: 2px;
}

.banner .centre .letter .top {
  margin-top: 56px;
  padding-bottom: 20px;
}

.banner .centre .letter .frame {
  margin-top: 70px;
  padding: 13px 0;
  width: 354px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  color: #FFFFFF;
}

.banner .centre .untitled {
  margin-left: 115px;
  margin-top: 172px;
  width: 516px;
  height: 498px;
  position: relative;
}

.banner .centre .untitled .cerebrum {
  width: 636px;
  position: absolute;
  top: -82px;
  left: -86px;
  z-index: 99;

}


.banner .centre .untitled .round {
  position: absolute;
  top: 52px;
  left: -80px;
}

.banner .centre .untitled .slash {
  position: absolute;
  top: 56px;
  left: -18px;
}

.footer-box {
  background-image: linear-gradient(to top left, #814e54, #545f65, #8cafb8);
}

.list {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}
  .phone-wrap {
    font-family: 'PingFang SC';
  }

  .background {
    background-image: url("../../assets/phone/Group770.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 1.14rem 0.6rem 0;
  }

  .nav .logo {
    display: flex;
    align-items: center;
    height: 0.43rem;
  }

  .nav .logo img {
    height: 0.35rem;
  }

  .nav .fork {
    height: 0.43rem;
    line-height: 0.43rem;
    color: #FFFFFF;
  }

  .nav .fork .iconfont {
    font-size: 0.36rem;
  }

  .phone-wrap .alternately {
    padding-top: 1.3rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
  }

  .alternately {
    padding-bottom: 0.81rem;
  }

  .alternately h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.6rem;
    line-height: 0.84rem;
    letter-spacing: 0.04rem;
    font-family: 'PingFang SC';
  }

  .alternately .line {
    margin-top: 0.4rem;
    width: 0.24rem;
    height: 0.03rem;
    background-color: #FFFFFF;
  }

  .alternately h3 span {
    font-size: 0.52rem;
  }

  .alternately .brain {
    position: relative;
    z-index: 6;
    /*width: 5.66rem;*/
    margin-top: 0.18rem;
  }

.alternately h2 {
  margin-top: 0.75rem;
  width: 5.90rem;
  font-size: 0.24rem;
  line-height: 0.36rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02rem;

}

.alternately h3 {
  margin-top: 0.2rem;
  padding-bottom: 0.46rem;
  width: 5.90rem;
  font-size: 0.24rem;
  line-height: 0.36rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02rem;
}

  .alternately .brain .pic {
    width: 6rem;
  }

  .alternately .brain .across {
    position: absolute;
    top: 1.3rem;
    left: 0.3rem;
    /*height: 2.21rem;*/
    width: 5.34rem;
  }

  .alternately .brain .vertical {
    position: absolute;
    top: 1.3rem;
    left: 0.54rem;
    /*height: 2.59rem;*/
    width: 4.8rem;
  }

  .technology {
    padding: 0.82rem 0.6rem 0.88rem;
    background-image: url("../../assets/phone/iStock-1181106241.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .technology .mro img {
    height: 0.28rem;
  }

  .technology .pic {
    margin-top: 0.34rem;
    line-height: 0;
  }

  .technology .pic img {
    height: 0.24rem;
  }

  .technology .line {
    margin-top: 0.5rem;
    height: 0.05rem;
    width: 0.44rem;
    background-color: #000000;
  }

  .technology .letter {
    margin-top: 0.5rem;
    line-height: 0;
  }

  .technology .letter img {
    height: 0.45rem;
  }

  .technology .text {
    margin-top: 0.4rem;
  }

  .technology .text p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.5rem;
    color: #111111;
    /*letter-spacing: 0.02rem;*/
  }

  .technology .text .top {
    margin-top: 0.2rem;
  }

  .img-text {
    padding: 1.8rem 0.6rem 1.28rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .img-text h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.38rem;
    line-height: 0.68rem;
    width: 2.4rem;
  }

  .img-text p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.48rem;
    letter-spacing: 0.02rem;
  }

  .end {
    display: flex;
    justify-content: flex-end;
  }

  .img-text .top {
    margin-top: 0.3rem;
  }

  .cooperation {
    padding: 1.22rem 0.6rem 1.39rem;
    background: #F0F3F5;
  }

  .cooperation h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.32rem;
    line-height: 0.3rem;
  }

  .cooperation .unit {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  .cooperation .unit .icon {
    position: relative;
    width: 1.48rem;
    height: 1.48rem;
  }

  .cooperation .unit .icon img {
    width: 1.48rem;
  }

  .cooperation .unit .icon p {
    position: absolute;
    bottom: 0.15rem;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 0.22rem;
    color: #111111;
    text-align: center;
  }
</style>
