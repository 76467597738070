<template>
<div>
  <div class="centre">
    <h2>合作单位</h2>
    <div class="cooperation">
    <div class="picture">
      <div class="mit">
        <img src="@/assets/mit.png"  width="100%" height="100%">
      </div>
      <p class="text" >MIT</p>
    </div>
      <div class="picture">
        <div class="white">
          <img src="@/assets/harmonize.png"  width="100%" height="100%">
        </div>
        <p>北京协和医院</p>
      </div>
      <div class="picture">
        <div class="white">
          <img src="@/assets/anzhen.png"  width="100%" height="100%">
        </div>
        <p>安贞医院</p>
      </div>
      <div class="picture">
          <div class="white">
              <img src="@/assets/fuwai.png"  width="100%" height="100%">
          </div>
          <p>阜外医院</p>
      </div>
      <div class="picture">
          <div class="white">
              <img src="@/assets/Stanford.png"  width="100%" height="100%">
          </div>
          <p>斯坦福大学</p>
      </div>
      <div class="picture">
        <div class="white">
          <img src="@/assets/jd-university.png" width="100%" height="100%">
        </div>
        <p>上海交大</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "cooperation.vue"
}
</script>

<style scoped>

.centre {
  font-family: 'PingFang SC';
  width: 1200px;
  margin: 100px auto;
  background: #F0F3F5;
  border-radius: 12px;
  padding:70px 80px 80px;
  box-sizing:border-box;
}

.centre h2 {
  margin-bottom: 60px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
}

.centre  .cooperation {
  display: flex;
  justify-content: space-between;
}

.centre .picture {
  height: 160px;
  width: 160px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.centre .picture .white {
  margin-top: 25px;
  margin-left: 40px;
  width: 80px;
  height: 80px;
  text-align: center;
  background-color: #FFFFFF;
}

.centre .picture .mit {
  margin-top: 43px;
  margin-left: 38px;
  width: 84px;
  height: 50px;
  text-align: center;
}

.centre .picture p {
  margin-top: 16px;
  text-align: center;
  color: #111111;
  font-size: 16px;
}

.centre .picture .text {
  margin-top: 30px;
}


</style>
