<template>
  <div class="interaction">
      <div class="apparel">
        <div class="center">
          <div class="exchange">
            <h2>智能穿戴</h2>
            <h2>人机交互</h2>
            <p>脑机接口与AI算法结合的意念万物互联</p>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
export default {
  name: "interaction.vue"
}
</script>

<style scoped>

.interaction {
}

 .apparel {
  margin: 0 auto;
  background-image:url("../../../assets/intellect-img.png");
  background-repeat:no-repeat;
  height:900px;

}

 .apparel .center {
   margin: 0 auto;
   width: 1200px;
 }

 .apparel .exchange {
   margin-left: 770px;
   padding-top:360px
}

 .apparel .exchange h2{
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 90px;
}

.apparel .exchange p{
  font-family: 'PingFang SC';
  font-weight: 400;
  font-size: 24px;
  line-height: 60px;
  color: #999999;
  margin-top:50px
}

</style>
