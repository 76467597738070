<template>
  <div class="interaction">
      <div class="apparel">
        <div class="center">
        <div class="exchange">
          <h2>人机交互</h2>
          <h2>俱身智能</h2>
          <p>人形机器人与智能穿戴结合的感知交互应用</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "interaction.vue"
}
</script>

<style scoped>

.interaction {
}

 .apparel {
   background-image: url("../../../assets/provide-img.png");
   background-repeat: no-repeat;
   background-position: right;
   height: 900px;
 }

 .apparel .center {
   width: 1200px;
   margin: 0 auto;
 }

 .apparel .exchange {
   padding-top: 360px;
}

 .apparel .exchange h2 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 90px;

}

.apparel .exchange p {
  font-family: 'PingFang SC';
  width: 330px;
  font-weight: 400;
  font-size: 24px;
  line-height: 60px;
  color: #999999;
  margin-top:50px
}

</style>
